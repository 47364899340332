export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Always-on.png',
    title: 'realUserMonitoring.section3.card1.title',
    content: 'realUserMonitoring.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Lightweight.png',
    title: 'realUserMonitoring.section3.card2.title',
    content: 'realUserMonitoring.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Proactive.png',
    title: 'realUserMonitoring.section3.card3.title',
    content: 'realUserMonitoring.section3.card3.content'
  }
]
